import React, { useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';

const HeroScene = () => {
  const group = useRef();
  
  // Animation de rotation
  useFrame((state, delta) => {
    group.current.rotation.y += delta * 0.2;
  });

  return (
    <group ref={group}>
      {/* Lumières */}
      <ambientLight intensity={0.5} />
      <directionalLight position={[10, 10, 5]} intensity={1} />
      <pointLight position={[-10, -10, -5]} intensity={0.5} />

      {/* Objets 3D simples en attendant les modèles GLTF */}
      <mesh position={[0, 0, 0]} rotation={[0, 0, 0]}>
        <sphereGeometry args={[1, 32, 32]} />
        <meshStandardMaterial color="#e65100" />
      </mesh>

      <mesh position={[2, 0, -2]} rotation={[0, 0, 0]}>
        <boxGeometry args={[1, 1, 1]} />
        <meshStandardMaterial color="#ff6f00" />
      </mesh>

      <mesh position={[-2, 0, -2]} rotation={[0, 0, 0]}>
        <cylinderGeometry args={[0.5, 0.5, 1, 32]} />
        <meshStandardMaterial color="#ff9100" />
      </mesh>
    </group>
  );
};

export default HeroScene;
