import React, { useState } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';

const MenuSectionContainer = styled.section`
  max-width: 1200px;
  margin: 4rem auto;
  padding: 0 1rem;
`;

const MenuHeader = styled.div`
  text-align: center;
  margin-bottom: 3rem;
`;

const MenuTitle = styled.h2`
  font-size: 2.5rem;
  color: var(--text-color);
  margin-bottom: 1rem;
`;

const MenuDescription = styled.p`
  color: var(--text-light);
  max-width: 600px;
  margin: 0 auto;
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
`;

const FilterButton = styled.button`
  padding: 0.5rem 1.5rem;
  border-radius: 25px;
  background: ${props => props.active ? 'var(--primary-color)' : 'transparent'};
  color: ${props => props.active ? 'white' : 'var(--text-color)'};
  border: 2px solid var(--primary-color);
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: var(--primary-color);
    color: white;
  }
`;

const SearchContainer = styled.div`
  max-width: 500px;
  margin: 0 auto 2rem;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 0.8rem 1.5rem;
  border-radius: 25px;
  border: 2px solid #ddd;
  font-size: 1rem;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: var(--primary-color);
    outline: none;
  }
`;

const MenuGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 2rem;
`;

const MenuItem = styled(motion.div)`
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const MenuItemImage = styled.div`
  height: 200px;
  background-color: #f5f5f5;
  background-image: ${props => props.image ? `url(${props.image})` : 'none'};
  background-size: cover;
  background-position: center;
`;

const MenuItemContent = styled.div`
  padding: 1.5rem;
`;

const MenuItemTitle = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
`;

const MenuItemDescription = styled.p`
  color: var(--text-light);
  font-size: 0.9rem;
  margin-bottom: 1rem;
`;

const MenuItemPrice = styled.span`
  color: var(--primary-color);
  font-weight: 600;
  font-size: 1.1rem;
`;

const MenuItemTags = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
`;

const Tag = styled.span`
  background: #f5f5f5;
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  font-size: 0.8rem;
  color: var(--text-light);
`;

// Données de démonstration
const menuItems = [
  {
    id: 1,
    name: "Tartare de Saumon",
    description: "Saumon frais coupé au couteau, avocat, mangue et vinaigrette aux agrumes",
    price: "18€",
    category: "entrees",
    tags: ["Poisson", "Sans Gluten"],
    image: "https://example.com/tartare.jpg"
  },
  {
    id: 2,
    name: "Foie Gras Maison",
    description: "Foie gras mi-cuit, chutney de figues et pain brioché toasté",
    price: "22€",
    category: "entrees",
    tags: ["Signature", "Fait Maison"],
    image: "https://example.com/foiegras.jpg"
  },
  {
    id: 3,
    name: "Carpaccio de Saint-Jacques",
    description: "Saint-Jacques fraîches, huile d'olive vierge, citron et copeaux de parmesan",
    price: "20€",
    category: "entrees",
    tags: ["Poisson", "Sans Gluten"],
    image: "https://example.com/carpaccio.jpg"
  },
  {
    id: 4,
    name: "Filet de Bœuf Rossini",
    description: "Filet de bœuf, escalope de foie gras poêlée, sauce aux truffes",
    price: "38€",
    category: "plats",
    tags: ["Signature", "Viande"],
    image: "https://example.com/rossini.jpg"
  },
  {
    id: 5,
    name: "Loup de Mer en Croûte de Sel",
    description: "Loup de mer entier cuit en croûte de sel, légumes de saison",
    price: "34€",
    category: "plats",
    tags: ["Poisson", "Pour 2 Personnes"],
    image: "https://example.com/loup.jpg"
  },
  {
    id: 6,
    name: "Risotto aux Truffes",
    description: "Risotto crémeux aux truffes noires et parmesan aged",
    price: "32€",
    category: "plats",
    tags: ["Végétarien", "Sans Gluten"],
    image: "https://example.com/risotto.jpg"
  },
  {
    id: 7,
    name: "Souris d'Agneau Confite",
    description: "Souris d'agneau confite pendant 7 heures, légumes racines",
    price: "29€",
    category: "plats",
    tags: ["Viande", "Fait Maison"],
    image: "https://example.com/agneau.jpg"
  },
  {
    id: 8,
    name: "Soufflé au Chocolat",
    description: "Soufflé au chocolat noir 70%, glace vanille bourbon",
    price: "14€",
    category: "desserts",
    tags: ["Végétarien", "Fait Maison"],
    image: "https://example.com/souffle.jpg"
  },
  {
    id: 9,
    name: "Tarte Tatin",
    description: "Tarte tatin aux pommes caramélisées, crème fraîche d'Isigny",
    price: "12€",
    category: "desserts",
    tags: ["Végétarien", "Signature"],
    image: "https://example.com/tatin.jpg"
  },
  {
    id: 10,
    name: "Crème Brûlée à la Vanille",
    description: "Crème brûlée à la vanille de Madagascar",
    price: "11€",
    category: "desserts",
    tags: ["Végétarien", "Sans Gluten"],
    image: "https://example.com/cremebrulee.jpg"
  },
  {
    id: 11,
    name: "Sélection de Vins Rouges",
    description: "Demandez notre carte des vins à votre serveur",
    price: "8-15€/verre",
    category: "boissons",
    tags: ["Alcool", "Vins"],
    image: "https://example.com/vinrouge.jpg"
  },
  {
    id: 12,
    name: "Champagne",
    description: "Sélection de champagnes prestigieux",
    price: "15-25€/verre",
    category: "boissons",
    tags: ["Alcool", "Signature"],
    image: "https://example.com/champagne.jpg"
  }
];

const categories = [
  { id: "all", name: "Tous" },
  { id: "entrees", name: "Entrées" },
  { id: "plats", name: "Plats" },
  { id: "desserts", name: "Desserts" },
  { id: "boissons", name: "Boissons" }
];

const MenuSection = () => {
  const [activeCategory, setActiveCategory] = useState("all");
  const [searchTerm, setSearchTerm] = useState("");

  const filteredItems = menuItems.filter(item => {
    const matchesCategory = activeCategory === "all" || item.category === activeCategory;
    const matchesSearch = item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         item.description.toLowerCase().includes(searchTerm.toLowerCase());
    return matchesCategory && matchesSearch;
  });

  return (
    <MenuSectionContainer id="menu-section">
      <MenuHeader>
        <MenuTitle>Notre Menu</MenuTitle>
        <MenuDescription>
          Découvrez notre sélection de plats raffinés, préparés avec les meilleurs ingrédients
          et servis avec passion.
        </MenuDescription>
      </MenuHeader>

      <FilterContainer>
        {categories.map(category => (
          <FilterButton
            key={category.id}
            active={activeCategory === category.id}
            onClick={() => setActiveCategory(category.id)}
          >
            {category.name}
          </FilterButton>
        ))}
      </FilterContainer>

      <SearchContainer>
        <SearchInput
          type="text"
          placeholder="Rechercher un plat..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </SearchContainer>

      <MenuGrid>
        {filteredItems.map(item => (
          <MenuItem
            key={item.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <MenuItemImage image={item.image} />
            <MenuItemContent>
              <MenuItemTitle>{item.name}</MenuItemTitle>
              <MenuItemDescription>{item.description}</MenuItemDescription>
              <MenuItemPrice>{item.price}</MenuItemPrice>
              <MenuItemTags>
                {item.tags.map(tag => (
                  <Tag key={tag}>{tag}</Tag>
                ))}
              </MenuItemTags>
            </MenuItemContent>
          </MenuItem>
        ))}
      </MenuGrid>
    </MenuSectionContainer>
  );
};

export default MenuSection;
