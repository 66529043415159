import React from 'react';
import styled from '@emotion/styled';

const FooterContainer = styled.footer`
  background-color: #1a1a1a;
  color: #fff;
  padding: 2rem 0;
  margin-top: auto;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
`;

const FooterSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const FooterLink = styled.a`
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: #e65100;
  }
`;

const ExternalLink = styled.a`
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: #e65100;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 1rem;
  font-size: 1.5rem;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterSection>
          <h3>Contact</h3>
          <p>Adresse: [Adresse du restaurant]</p>
          <p>Téléphone: [Numéro]</p>
          <p>Email: contact@socy.fr</p>
        </FooterSection>

        <FooterSection>
          <h3>Liens Rapides</h3>
          <FooterLink href="/blog/">Blog</FooterLink>
          <ExternalLink href="https://socy.fr" target="_blank" rel="noopener noreferrer">
            socy.fr
          </ExternalLink>
        </FooterSection>

        <FooterSection>
          <h3>Suivez-nous</h3>
          <SocialIcons>
            <ExternalLink href="https://facebook.com" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook"></i>
            </ExternalLink>
            <ExternalLink href="https://instagram.com" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram"></i>
            </ExternalLink>
            <ExternalLink href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-twitter"></i>
            </ExternalLink>
          </SocialIcons>
        </FooterSection>

        <FooterSection>
          <h3>Mentions Légales</h3>
          <FooterLink href="/mentions-legales">Mentions Légales</FooterLink>
          <FooterLink href="/politique-confidentialite">Politique de Confidentialité</FooterLink>
        </FooterSection>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
