import React, { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';

// Components
import HeroScene from '../components/three/HeroScene';
import FeatureBlocks from '../components/FeatureBlocks';
import MenuSection from '../components/MenuSection';
import ReservationSection from '../components/ReservationSection';
import GallerySection from '../components/GallerySection';
import ReviewsSection from '../components/ReviewsSection';
import ContactForm from '../components/ContactForm';

const HeroContainer = styled.div`
  height: 100vh;
  width: 100%;
  position: relative;
`;

const HeroContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  z-index: 1;
`;

const HeroTitle = styled(motion.h1)`
  font-size: 3.5rem;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
`;

const HeroDescription = styled(motion.p)`
  font-size: 1.2rem;
  margin-bottom: 2rem;
  max-width: 600px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
`;

const CTAButton = styled(motion.button)`
  padding: 1rem 2rem;
  font-size: 1.1rem;
  background-color: #e65100;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff6f00;
  }
`;

const Home = () => {
  const scrollToMenu = () => {
    document.getElementById('menu-section').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <HeroContainer id="hero">
        <Canvas>
          <Suspense fallback={null}>
            <HeroScene />
            <OrbitControls enableZoom={false} />
          </Suspense>
        </Canvas>
        <HeroContent>
          <HeroTitle
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            Piquin Restaurant - Une Expérience Culinaire Inoubliable
          </HeroTitle>
          <HeroDescription
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            Bienvenue chez Piquin Restaurant, où la gastronomie rencontre l'élégance.
            Découvrez notre menu raffiné et vivez une expérience culinaire exceptionnelle.
          </HeroDescription>
          <CTAButton
            onClick={scrollToMenu}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Découvrez Notre Menu
          </CTAButton>
        </HeroContent>
      </HeroContainer>

      <FeatureBlocks />
      <MenuSection />
      <ReservationSection />
      <GallerySection />
      <ReviewsSection />
      <ContactForm />
    </>
  );
};

export default Home;
