import React from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';

const FeaturesContainer = styled.section`
  max-width: 1200px;
  margin: 4rem auto;
  padding: 0 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
`;

const FeatureBlock = styled(motion.div)`
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const Icon = styled.div`
  font-size: 2.5rem;
  color: #e65100;
  margin-bottom: 1rem;
`;

const Title = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #333;
`;

const Description = styled.p`
  color: #666;
  line-height: 1.6;
`;

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2
    }
  }
};

const blockVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6
    }
  }
};

const FeatureBlocks = () => {
  return (
    <FeaturesContainer
      as={motion.div}
      variants={containerVariants}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
    >
      <FeatureBlock variants={blockVariants}>
        <Icon>
          <i className="fas fa-utensils"></i>
        </Icon>
        <Title>Notre Menu</Title>
        <Description>
          Explorez notre sélection de plats raffinés et créatifs, préparés avec passion
          par nos chefs talentueux. Une cuisine qui marie tradition et innovation.
        </Description>
      </FeatureBlock>

      <FeatureBlock variants={blockVariants}>
        <Icon>
          <i className="far fa-calendar-alt"></i>
        </Icon>
        <Title>Réservations en Ligne</Title>
        <Description>
          Réservez votre table en quelques clics grâce à notre système de réservation
          simple et efficace. Garantissez votre place pour une expérience inoubliable.
        </Description>
      </FeatureBlock>

      <FeatureBlock variants={blockVariants}>
        <Icon>
          <i className="far fa-images"></i>
        </Icon>
        <Title>Galerie de Photos</Title>
        <Description>
          Découvrez l'ambiance unique de notre restaurant et la présentation artistique
          de nos plats à travers notre collection d'images soigneusement sélectionnées.
        </Description>
      </FeatureBlock>
    </FeaturesContainer>
  );
};

export default FeatureBlocks;
