import React, { useState } from 'react';
import styled from '@emotion/styled';
import { motion, AnimatePresence } from 'framer-motion';

const ReviewsContainer = styled.section`
  max-width: 1200px;
  margin: 4rem auto;
  padding: 0 1rem;
`;

const ReviewsHeader = styled.div`
  text-align: center;
  margin-bottom: 3rem;
`;

const ReviewsTitle = styled.h2`
  font-size: 2.5rem;
  color: var(--text-color);
  margin-bottom: 1rem;
`;

const ReviewsDescription = styled.p`
  color: var(--text-light);
  max-width: 600px;
  margin: 0 auto;
`;

const ReviewsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  margin-bottom: 3rem;
`;

const ReviewCard = styled(motion.div)`
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ReviewHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const ReviewerAvatar = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #f5f5f5;
  margin-right: 1rem;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ReviewerInfo = styled.div`
  flex-grow: 1;
`;

const ReviewerName = styled.h4`
  margin: 0;
  color: var(--text-color);
`;

const ReviewDate = styled.span`
  font-size: 0.9rem;
  color: var(--text-light);
`;

const StarRating = styled.div`
  color: #ffd700;
  margin-bottom: 0.5rem;
`;

const ReviewText = styled.p`
  color: var(--text-light);
  line-height: 1.6;
`;

const ReviewForm = styled.form`
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  color: var(--text-color);
  font-weight: 500;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.8rem;
  border: 2px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: var(--primary-color);
    outline: none;
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  padding: 0.8rem;
  border: 2px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
  resize: vertical;
  min-height: 100px;

  &:focus {
    border-color: var(--primary-color);
    outline: none;
  }
`;

const RatingInput = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const StarButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  color: ${props => props.filled ? '#ffd700' : '#ddd'};
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #ffd700;
  }
`;

const SubmitButton = styled(motion.button)`
  width: 100%;
  padding: 1rem;
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background: var(--primary-hover);
  }
`;

// Données de démonstration
const reviews = [
  {
    id: 1,
    name: "Sophie Martin",
    date: "15 décembre 2023",
    rating: 5,
    text: "Une expérience culinaire exceptionnelle ! Les plats sont raffinés et le service est impeccable. Je recommande vivement le tartare de saumon en entrée.",
    avatar: "https://example.com/avatar1.jpg"
  },
  {
    id: 2,
    name: "Jean-Pierre Dubois",
    date: "10 décembre 2023",
    rating: 5,
    text: "Le filet de bœuf Rossini était tout simplement divin. La cuisson parfaite et la sauce aux truffes sublime. Le service est attentionné et l'ambiance très agréable.",
    avatar: "https://example.com/avatar2.jpg"
  },
  {
    id: 3,
    name: "Marie Leroy",
    date: "5 décembre 2023",
    rating: 4,
    text: "Très belle découverte ! Le risotto aux truffes est excellent. Seul petit bémol, l'attente était un peu longue, mais la qualité des plats compense largement.",
    avatar: "https://example.com/avatar3.jpg"
  },
  {
    id: 4,
    name: "Thomas Bernard",
    date: "1 décembre 2023",
    rating: 5,
    text: "Le soufflé au chocolat est une pure merveille ! Un dessert qui vaut vraiment le détour. Le cadre est élégant et le personnel très professionnel.",
    avatar: "https://example.com/avatar4.jpg"
  },
  {
    id: 5,
    name: "Claire Moreau",
    date: "28 novembre 2023",
    rating: 5,
    text: "Une soirée mémorable ! La carte des vins est exceptionnelle et le sommelier de très bon conseil. Le loup en croûte de sel était parfaitement exécuté.",
    avatar: "https://example.com/avatar5.jpg"
  },
  {
    id: 6,
    name: "Laurent Petit",
    date: "25 novembre 2023",
    rating: 4,
    text: "Excellent restaurant gastronomique. Les plats sont bien présentés et savoureux. La souris d'agneau était fondante à souhait. Un très bon rapport qualité-prix.",
    avatar: "https://example.com/avatar6.jpg"
  }
];

const ReviewsSection = () => {
  const [formData, setFormData] = useState({
    name: '',
    rating: 0,
    review: ''
  });

  const [hoveredStar, setHoveredStar] = useState(0);

  const handleStarClick = (rating) => {
    setFormData(prev => ({ ...prev, rating }));
  };

  const handleStarHover = (rating) => {
    setHoveredStar(rating);
  };

  const handleStarLeave = () => {
    setHoveredStar(0);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Ici, vous pouvez ajouter la logique pour envoyer l'avis
    console.log('Avis soumis:', formData);
    // Réinitialiser le formulaire
    setFormData({
      name: '',
      rating: 0,
      review: ''
    });
  };

  return (
    <ReviewsContainer id="reviews-section">
      <ReviewsHeader>
        <ReviewsTitle>Avis de nos Clients</ReviewsTitle>
        <ReviewsDescription>
          Découvrez ce que nos clients pensent de leur expérience chez Piquin Restaurant.
        </ReviewsDescription>
      </ReviewsHeader>

      <ReviewsGrid>
        {reviews.map((review) => (
          <ReviewCard
            key={review.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <ReviewHeader>
              <ReviewerAvatar>
                <img src={review.avatar} alt={review.name} />
              </ReviewerAvatar>
              <ReviewerInfo>
                <ReviewerName>{review.name}</ReviewerName>
                <ReviewDate>{review.date}</ReviewDate>
              </ReviewerInfo>
            </ReviewHeader>
            <StarRating>
              {'★'.repeat(review.rating)}
              {'☆'.repeat(5 - review.rating)}
            </StarRating>
            <ReviewText>{review.text}</ReviewText>
          </ReviewCard>
        ))}
      </ReviewsGrid>

      <ReviewForm onSubmit={handleSubmit}>
        <h3>Partagez votre expérience</h3>
        <FormGroup>
          <Label htmlFor="name">Votre nom</Label>
          <Input
            type="text"
            id="name"
            value={formData.name}
            onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
            required
          />
        </FormGroup>

        <FormGroup>
          <Label>Votre note</Label>
          <RatingInput>
            {[1, 2, 3, 4, 5].map((star) => (
              <StarButton
                key={star}
                type="button"
                filled={star <= (hoveredStar || formData.rating)}
                onClick={() => handleStarClick(star)}
                onMouseEnter={() => handleStarHover(star)}
                onMouseLeave={handleStarLeave}
              >
                ★
              </StarButton>
            ))}
          </RatingInput>
        </FormGroup>

        <FormGroup>
          <Label htmlFor="review">Votre avis</Label>
          <Textarea
            id="review"
            value={formData.review}
            onChange={(e) => setFormData(prev => ({ ...prev, review: e.target.value }))}
            required
          />
        </FormGroup>

        <SubmitButton
          type="submit"
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          Publier mon avis
        </SubmitButton>
      </ReviewForm>
    </ReviewsContainer>
  );
};

export default ReviewsSection;
