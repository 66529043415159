import React, { useState } from 'react';
import styled from '@emotion/styled';
import { motion, AnimatePresence } from 'framer-motion';

const ReservationContainer = styled.section`
  max-width: 1200px;
  margin: 4rem auto;
  padding: 0 1rem;
`;

const ReservationHeader = styled.div`
  text-align: center;
  margin-bottom: 3rem;
`;

const ReservationTitle = styled.h2`
  font-size: 2.5rem;
  color: var(--text-color);
  margin-bottom: 1rem;
`;

const ReservationDescription = styled.p`
  color: var(--text-light);
  max-width: 600px;
  margin: 0 auto;
`;

const ReservationForm = styled.form`
  max-width: 600px;
  margin: 0 auto;
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  color: var(--text-color);
  font-weight: 500;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.8rem;
  border: 2px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: var(--primary-color);
    outline: none;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 0.8rem;
  border: 2px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
  appearance: none;
  background: white;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 0.7rem top 50%;
  background-size: 0.65rem auto;

  &:focus {
    border-color: var(--primary-color);
    outline: none;
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  padding: 0.8rem;
  border: 2px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
  resize: vertical;
  min-height: 100px;

  &:focus {
    border-color: var(--primary-color);
    outline: none;
  }
`;

const SubmitButton = styled(motion.button)`
  width: 100%;
  padding: 1rem;
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background: var(--primary-hover);
  }
`;

const Confirmation = styled(motion.div)`
  text-align: center;
  padding: 2rem;
  background: #e8f5e9;
  border-radius: 8px;
  margin-top: 2rem;
`;

const ReservationSection = () => {
  const [formData, setFormData] = useState({
    date: '',
    time: '',
    guests: '',
    name: '',
    email: '',
    phone: '',
    specialRequests: ''
  });

  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Ici, vous pouvez ajouter la logique pour envoyer les données de réservation
    console.log('Réservation soumise:', formData);
    setShowConfirmation(true);
    // Réinitialiser le formulaire
    setFormData({
      date: '',
      time: '',
      guests: '',
      name: '',
      email: '',
      phone: '',
      specialRequests: ''
    });
  };

  return (
    <ReservationContainer id="reservation-section">
      <ReservationHeader>
        <ReservationTitle>Réservez Votre Table</ReservationTitle>
        <ReservationDescription>
          Réservez votre table en quelques clics et vivez une expérience gastronomique inoubliable.
        </ReservationDescription>
      </ReservationHeader>

      <ReservationForm onSubmit={handleSubmit}>
        <FormGroup>
          <Label htmlFor="date">Date</Label>
          <Input
            type="date"
            id="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
            required
          />
        </FormGroup>

        <FormGroup>
          <Label htmlFor="time">Heure</Label>
          <Select
            id="time"
            name="time"
            value={formData.time}
            onChange={handleChange}
            required
          >
            <option value="">Sélectionnez une heure</option>
            <option value="12:00">12:00</option>
            <option value="12:30">12:30</option>
            <option value="13:00">13:00</option>
            <option value="13:30">13:30</option>
            <option value="19:00">19:00</option>
            <option value="19:30">19:30</option>
            <option value="20:00">20:00</option>
            <option value="20:30">20:30</option>
            <option value="21:00">21:00</option>
          </Select>
        </FormGroup>

        <FormGroup>
          <Label htmlFor="guests">Nombre de personnes</Label>
          <Select
            id="guests"
            name="guests"
            value={formData.guests}
            onChange={handleChange}
            required
          >
            <option value="">Sélectionnez le nombre de personnes</option>
            {[1, 2, 3, 4, 5, 6, 7, 8].map(num => (
              <option key={num} value={num}>{num} personne{num > 1 ? 's' : ''}</option>
            ))}
          </Select>
        </FormGroup>

        <FormGroup>
          <Label htmlFor="name">Nom complet</Label>
          <Input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </FormGroup>

        <FormGroup>
          <Label htmlFor="email">Email</Label>
          <Input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </FormGroup>

        <FormGroup>
          <Label htmlFor="phone">Téléphone</Label>
          <Input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </FormGroup>

        <FormGroup>
          <Label htmlFor="specialRequests">Demandes spéciales</Label>
          <Textarea
            id="specialRequests"
            name="specialRequests"
            value={formData.specialRequests}
            onChange={handleChange}
            placeholder="Allergies, préférences alimentaires, occasions spéciales..."
          />
        </FormGroup>

        <SubmitButton
          type="submit"
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          Réserver ma table
        </SubmitButton>
      </ReservationForm>

      <AnimatePresence>
        {showConfirmation && (
          <Confirmation
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
          >
            <h3>Réservation Confirmée!</h3>
            <p>
              Merci pour votre réservation. Nous vous enverrons un email de confirmation
              dans les plus brefs délais.
            </p>
          </Confirmation>
        )}
      </AnimatePresence>
    </ReservationContainer>
  );
};

export default ReservationSection;
